h1, h2, h3, h4, nav, summary, button#start {
	font-weight: 900;
}

#settings[open] {
	height: 389.5px;
}

#settings p {
	max-width: 490px;
}

@media (min-width: 700px) {
	#about[open] {
		height: 422px;
	}
}